import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generator',
  templateUrl: './generator.component.html',
  styleUrls: ['./generator.component.css']
})

export class GeneratorComponent implements OnInit {
  // Declaring the fields
  givenFields: string[];
  thenFields: string[];
  acList: AcceptanceCriteria[];
  reverseAcList: AcceptanceCriteria[];
  hasError: boolean;
  givenError: boolean;
  whenError: boolean;
  thenError: boolean;
  editMode: boolean;
  editID: number;
  constructor() {
    // Setting initial values
    this.givenFields = [];
    this.thenFields = [];
    this.acList = [];
    this.reverseAcList = [];
    this.hasError = false;
    this.givenError = false;
    this.whenError = false;
    this.thenError = false;
    this.editMode = false;
    this.editID = 0;
  }

  ngOnInit() {
    // If previous data exists in local storage it will bring it back in
    let previousData = JSON.parse(localStorage.getItem("AC List"));
    if (previousData != null) {
      this.acList = previousData;
      // Not only does this update the IDs of the ACs, it also 
      // sets the reverse list which is what is actually being displayed
      this.UpdateAcId();
    }
  }


  keyUp(event: KeyboardEvent, field: string) {
    // If the space key is pressed
    if (event.keyCode === 32) {
      if (field === "given") {
        this.addGivenField();
      } else if (field === "then") {
        this.addThenField();
      }
    }
  }

  clearForm() {
    let givenInput: any = document.getElementById("Given_1");
    //Removes all of the given and fields
    givenInput.value = "";
    this.givenFields = [];
    let whenInput: any = document.getElementById("When");
    whenInput.value = "";
    let thenInput: any = document.getElementById("Then_1");
    thenInput.value = "";
    //Removes all of the then and fields
    this.thenFields = [];
    // Adds the AND buttons back in
    document.getElementById("given-and-button").style.display = "block";
    document.getElementById("then-and-button").style.display = "block";
  }

  addGivenField() {
    if (this.givenFields.length < 3) {
      // Adds 2 to length because "Given_1" already exists but is not in the array
      this.givenFields.push("Given_" + (this.givenFields.length + 2));
    }
    if (this.givenFields.length >= 3) {
      // Use display = "none" instead of removing the element so it can be added later.
      // visibility = "hidden" still allocates space and so isn't ideal
      document.getElementById("given-and-button").style.display = "none";
    }
  }

  addThenField() {
    // Checks if max number of "Then" fields has been reached
    if (this.thenFields.length < 3) {
      // Adds 2 to length because "Then_1" already exists but is not in the array
      this.thenFields.push("Then_" + (this.thenFields.length + 2));
    }
    // Removes the AND button element for the "Then"
    if (this.thenFields.length >= 3) {
      // Use display = "none" instead of removing the element so it can be added later.
      // visibility = "hidden" still allocates space and so isn't ideal
      document.getElementById("then-and-button").style.display = "none";
    }
  }

  CheckFieldRequirements() {
    let input: any;
    let metRequirements: boolean = true;
    input = document.getElementById("Given_1");
    if (input.value === "") {
      this.givenError = true;
      this.hasError = true;
      metRequirements = false;
    } else {
      this.givenError = false;
    }
    input = document.getElementById("When");
    if (input.value === "") {
      this.whenError = true;
      this.hasError = true;
      metRequirements = false;
    } else {
      this.whenError = false;
    }
    input = document.getElementById("Then_1");
    if (input.value === "") {
      this.thenError = true;
      this.hasError = true;
      metRequirements = false;
    } else {
      this.thenError = false;
    }
    return metRequirements;
  }

  AddAc() {
    if (this.CheckFieldRequirements()) {
      this.hasError = false;
      let input: any;
      // Initial values of the AC
      let ac: AcceptanceCriteria = {
        id: 0,
        given: "",
        givenAndList: [],
        when: "",
        then: "",
        thenAndList: []
      };
      // Getting the values from the first Given field
      input = document.getElementById("Given_1");
      ac.given = input.value;
      input.value = "";
      // Iterating through any Given And fields and getting values from them
      for (let givenField of this.givenFields) {
        input = document.getElementById(givenField);
        // If the field AND field is empty it won't be added to the AC
        if (input.value != "") {
          ac.givenAndList.push(input.value);
          input.value = "";
        }

      }
      // Getting the values from the When field
      input = document.getElementById("When");
      ac.when = input.value;
      input.value = "";
      // Getting the values from the Then field
      input = document.getElementById("Then_1");
      ac.then = input.value;
      input.value = "";
      // Iterating through any Then And fields and getting values from them
      for (let thenField of this.thenFields) {
        input = document.getElementById(thenField);
        // If an AND field is empty it will not be added to the AC
        if (input.value != "") {
          ac.thenAndList.push(input.value);
          input.value = "";
        }

      }
      // Checks if in editMode
      if (this.editMode) {
        ac.id = this.editID;
        // The index of the item in acList that will be updated
        let editIndex = this.editID - 1;
        this.acList[editIndex] = ac;

      } else {
        // Will submit AC as a new entry

        // Because the index of an array starts at 0 and we want IDs to start at 1,
        // 1 is added to the index to get the ID
        ac.id = this.acList.length + 1;
        // Adding the value to the list of ACs
        this.acList.push(ac);
      }
      // A reverse list is created based on the AC list. This is the one used
      // to display so that the newest AC appear first 
      this.reverseAcList = this.acList.slice().reverse();
      // Saves the AC list to local storage
      localStorage.setItem("AC List", JSON.stringify(this.acList));
      // Clears the form
      this.clearForm();
      // Returns focus to the first Given box
      document.getElementById("Given_1").focus();
      // Disables edit mode
      this.editMode = false;
      this.editID = 0;
      // Ensures the text of the submit button is "Submit"
      let btnSubmit: any = document.getElementById("submit-button");
      btnSubmit.innerText = "Submit";
    }
  }

  DeleteAc(id) {
    // AC IDs start at 1 so need to subtract 1 to get the array index
    let index: number = id - 1;
    this.acList.splice(index, 1);
    this.UpdateAcId();
    this.reverseAcList = this.acList.slice().reverse();
    // Saves updated AC List back to local storage
    localStorage.setItem("AC List", JSON.stringify(this.acList));
  }
  // Updates the ID of all ACs in the list after deletions
  UpdateAcId() {
    for (let ac of this.acList) {
      ac.id = (this.acList.indexOf(ac) + 1);
    }
    this.reverseAcList = this.acList.slice().reverse();
  }

  EditAc(ac: AcceptanceCriteria) {
    // Enables edit mode so that the app knows to overwrite an AC instead of submit a new one
    this.editMode = true;
    // Stores the ID of the item being edited
    this.editID = ac.id;
    // Changes the text of the submit button to "Update"
    let btnSubmit: any = document.getElementById("submit-button");
    btnSubmit.innerText = "Update";
    //Clears the form first to prevent and boxes from stacking
    this.clearForm();
    let givenInput: any = document.getElementById("Given_1");
    givenInput.value = ac.given;
    for (let givenAnd of ac.givenAndList) {
      this.addGivenField();
      // Hacky build around with the timeout until I work out a better way to do this
      setTimeout(() => {
        let givenAndInput: any = document.getElementById("Given_" + (ac.givenAndList.indexOf(givenAnd) + 2));
        givenAndInput.value = givenAnd;
      }, 500);
    }
    let whenInput: any = document.getElementById("When");
    whenInput.value = ac.when;
    let thenInput: any = document.getElementById("Then_1");
    thenInput.value = ac.then;
    for (let thenAnd of ac.thenAndList) {
      // Hacky build around with the timeout until I work out a better way to do this
      this.addThenField();
      setTimeout(() => {
        let thenAndInput: any = document.getElementById("Then_" + (ac.thenAndList.indexOf(thenAnd) + 2));
        thenAndInput.value = thenAnd;
      }, 500)
    }
  }
  CancelEdit() {
    // Disables edit mode and clears the form
    this.editMode = false;
    this.editID = 0;
    this.clearForm();
    let btnSubmit: any = document.getElementById("submit-button");
    // Changes the text on the submit button back to "Submit"
    btnSubmit.innerText = "Submit";
  }

  download(filename: any) {
    //Finding which format to export
    let exportOption: any = document.getElementById("select-format");
    let content: string;
    let dataString: string;
    if (exportOption.value === "JSON") {
      // Downloading to JSON
      filename += ".json"
      dataString = "data:text/json;charset=utf-8,";
      content = this.getJSONContent();
    } else if (exportOption.value === "CSV") {
      // Downloading to CSV
      filename += ".csv";
      dataString = "data:text/csv;charset=utf-8,";
      content = this.getCSVContent();

    } else {
      // Downloading to textfile
      filename += ".txt";
      dataString = "data:text/plain;charset=utf-8,";
      content = this.getTextContent();

    }
    // Actually creating the document
    let link = document.createElement('a');
    link.setAttribute('href', dataString + encodeURIComponent(content));
    link.setAttribute('download', filename);

    if (document.createEvent) {
      let event = document.createEvent('MouseEvents');
      event.initEvent('click', true, true);
      link.dispatchEvent(event);
    }
    else {
      link.click();
    }

  }

  getTextContent() {
    let content = "";
    for (let ac of this.acList) {
      content += "AC: " + ac.id + "\r\n";
      content += "Given " + ac.given + "\r\n";
      for (let givenAnd of ac.givenAndList) {
        content += "And " + givenAnd + "\r\n";
      }
      content += "When " + ac.when + "\r\n";
      content += "Then " + ac.then + "\r\n";
      for (let thenAnd of ac.thenAndList) {
        content += "And " + thenAnd + "\r\n";
      }
      content += "\r\n";
    }
    return content;
  }

  getCSVContent() {
    let content: string = "AC,Given,And,And,And,When,Then,And,And,And,\r\n";
    /*
      Have to add quote marks around each input so that commas in the input
      Don't mess with formating
    */
    for (let ac of this.acList) {
      content += "\"" + ac.id + "\",";
      content += "\"" + ac.given + "\",";
      for (let givenAnd of ac.givenAndList) {
        content += "\"" + givenAnd + "\",";
      }
      let commasToAdd = 3 - ac.givenAndList.length;
      for (let i = 0; i < commasToAdd; i++) {
        content += ",";
      }
      content += "\"" + ac.when + "\",";
      content += "\"" + ac.then + "\",";
      for (let thenAnd of ac.thenAndList) {
        content += "\"" + thenAnd + "\",";
      }
      commasToAdd = 3 - ac.thenAndList.length;
      for (let i = 0; i < commasToAdd; i++) {
        content += ",";
      }
      content += "\r\n";
    }
    return content;
  }

  getJSONContent() {
    let content: string = JSON.stringify(this.acList);
    return content;
  }

  clearList() {
    if(confirm("Do you want to delete all items in AC list?")){
       // Clears the lists of ACs
    this.acList = [];
    this.reverseAcList = [];
    // Saves the AC list to local storage
    localStorage.setItem("AC List", JSON.stringify(this.acList));
    // Clears the form
    this.clearForm();
    // Returns focus to the first Given box
    document.getElementById("Given_1").focus();
    // Disables edit mode
    this.editMode = false;
    this.editID = 0;
    // Ensures the text of the submit button is "Submit"
    let btnSubmit: any = document.getElementById("submit-button");
    btnSubmit.innerText = "Submit";
    }
   
  }

}
interface AcceptanceCriteria {
  id: number,
  given: string,
  givenAndList: string[],
  when: string,
  then: string,
  thenAndList: string[]
}