/*
Used for setting focus to generated textboxes.
*/
import { OnInit, Directive, Renderer, ElementRef, Input, Component } from '@angular/core';
@Directive({ selector: '[focusInput]' })
export class FocusInputDirective {
    constructor(public renderer: Renderer, public elementRef: ElementRef) {


    }
    ngOnInit() {
        this.renderer.invokeElementMethod(this.elementRef.nativeElement, 'focus');
    }

}