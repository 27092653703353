import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule, Routes} from '@angular/router';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { GeneratorComponent } from './components/generator/generator.component';
import { FocusInputDirective} from './directives/focus-input.directive';
import{ ReversePipe} from './pipes/reverse.pipe';
import { AboutComponent } from './components/about/about.component';

// Defining routes
const appRoutes: Routes = [
  {path:'', component: GeneratorComponent},
  {path:'about', component: AboutComponent}
]

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    GeneratorComponent,
    FocusInputDirective,
    ReversePipe,
    AboutComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    RouterModule.forRoot(appRoutes)
  ],
  
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
